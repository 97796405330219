import {SERVER_BASE_URL} from '@wix/wix-events-commons-statics'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import s from './map.scss'
import {MapProps} from './index'

export const Map: React.FC<MapProps> = ({mobile, coordinates, language, instance}) => (
  <div id="event-details-page-map" className={mobile ? s.mobileMap : s.map} data-hook={DH.MAP}>
    <iframe
      src={`${SERVER_BASE_URL}/html/google-map?locale=${language}&lat=${coordinates.lat}&lng=${coordinates.lng}&instance=${instance}`}
      style={{height: '100%', width: '100%'}}
      allowFullScreen
      title="Map"
    />
  </div>
)
