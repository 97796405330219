import * as React from 'react'
import classNames from 'classnames'
import c from '../../../../classnames.scss'
import {isHollowButton} from '../../../../../selectors/settings'
import s from './updated-indication.scss'
import {UpdatedIndicationProps} from './interfaces'

export const UpdatedIndication = ({formattedUpdatedDate, t, notFirstItem, buttonStyle}: UpdatedIndicationProps) => {
  const hollowButtonUsed = isHollowButton(buttonStyle)

  return (
    <div className={classNames(s.container, {[s.notFirstItem]: notFirstItem})}>
      <div
        className={classNames(s.pimple, {
          [s.pimpleHollowButton]: hollowButtonUsed,
          [s.pimpleFullButton]: !hollowButtonUsed,
        })}
      />
      <div className={classNames(s.updatedIndicationText, c.scheduleDurationFont)}>
        {t('updated')} {formattedUpdatedDate}
      </div>
    </div>
  )
}
