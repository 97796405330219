import React from 'react'
import {Button, PRIORITY} from 'wix-ui-tpa/Button'
import classNames from 'classnames'
import {classes} from './secondary-button.st.css'
import {SecondaryButtonProps} from '.'

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  hollowButton,
  onClick,
  className,
  mobile,
  roundedButton,
  dataHook,
  children,
}) => (
  <Button
    upgrade
    priority={PRIORITY.basicSecondary}
    className={classNames(classes.root, className, hollowButton ? classes.hollow : classes.full, {
      [classes.mobile]: mobile,
      [classes.rounded]: roundedButton,
    })}
    onClick={onClick}
    data-hook={dataHook}
  >
    {children}
  </Button>
)
