import React from 'react'
import {EventDetailsImageRatio} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import {EventImage} from '../../event-image'
import s from './mobile-image.scss'
import {MobileImageProps} from '.'

const IMAGE_SIZE = {
  [EventDetailsImageRatio['1:1']]: {
    width: 320,
    height: 320,
  },
  [EventDetailsImageRatio['1:2']]: {
    width: 320,
    height: 160,
  },
}

export const MobileImage: React.FC<MobileImageProps> = ({imageRatio, isSquareImage}) => {
  const container = IMAGE_SIZE[imageRatio]

  return <EventImage className={classNames(s.container, isSquareImage ? s.square : s.wide)} container={container} />
}
