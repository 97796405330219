import classNames from 'classnames'
import React from 'react'
import {EventDetailsImageRatio} from '@wix/wix-events-commons-statics'
import {EventImage} from '../../../../event-image'
import s from './image.scss'
import {ImageProps} from './interfaces'

const IMAGE_SIZE = {
  [EventDetailsImageRatio['1:1']]: {
    width: 844,
    height: 844,
  },
  [EventDetailsImageRatio['1:2']]: {
    width: 980,
    height: 490,
  },
}

export const getImageSize = (imageRatio: EventDetailsImageRatio = EventDetailsImageRatio['1:1']) =>
  IMAGE_SIZE[imageRatio]

export const Image: React.FC<ImageProps> = ({imageVisible, mainImage, isSquareImage, imageRatio, crop}) => {
  if (!imageVisible) {
    return null
  }
  const container = crop ? getImageSize(imageRatio) : {height: mainImage.height || 1080, width: mainImage.width || 1920}

  return (
    <EventImage
      className={classNames(s.container, isSquareImage ? s.squareImage : s.wideImage)}
      container={container}
      doubleHeight
    />
  )
}
