import {SelectedTickets} from '../types'

export const calculateSubPrice = (price: number, quantity: number) => (Math.round(price * 100) * quantity) / 100

const getSelectedTicketsCount = (id: string, selectedTickets: SelectedTickets) => selectedTickets[id] || 0

const getSelectedAndNotFreeTickets = (
  tickets: wix.events.ticketing.TicketDefinition[],
  selectedTickets: SelectedTickets,
) => tickets.filter(({free, id}) => !free && !!getSelectedTicketsCount(id, selectedTickets))

export const calculateTicketsTotalPrice = (
  tickets: wix.events.ticketing.TicketDefinition[],
  selectedTickets: SelectedTickets,
) =>
  getSelectedAndNotFreeTickets(tickets, selectedTickets).reduce(
    (sum: number, {price: {amount}, id}: wix.events.ticketing.TicketDefinition) =>
      sum + Number(amount) * getSelectedTicketsCount(id, selectedTickets),
    0,
  )
