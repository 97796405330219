import {
  getFormControls,
  getFormMessages,
  isDraft,
  isNoResponseEnabled,
  isTicketed,
  isWaitlistOpen,
  validate,
} from '@wix/wix-events-commons-statics'
import {reduxForm} from 'redux-form'
import {getRsvpError, getResponse} from '../../selectors/rsvp'
import {getFormButtonStyle, isFullButton} from '../../selectors/settings'
import {RegFormData} from '../../types'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {getReservationId} from '../../selectors/reservation'
import {hasPolicies} from '../../selectors/policies'
import {isValidating, isEditingCoupon} from '../../selectors/invoice'
import {REGISTRATION_FORM_ID} from './constants'
import {Form as Presentation} from './form'
import {ConnectedFormOwnProps, FormOwnProps, FormRuntimeProps} from './interfaces'
import {onSubmitFail} from './utils'

const mapRuntimeToProps = (
  {state, actions: {resetRsvpError, changeResponse}}: DetailsPageAppProps,
  {t}: FormOwnProps,
): FormRuntimeProps => {
  const event = state.event
  const buttonStyle = getFormButtonStyle(state.component.settings)

  return {
    controls: getFormControls(event),
    messages: getFormMessages(event),
    noResponseEnabled: isNoResponseEnabled(event),
    hasPolicies: hasPolicies(state),
    waitlistOpen: isWaitlistOpen(event),
    reservationId: getReservationId(state),
    ticketed: isTicketed(event),
    buttonStyle,
    error: getRsvpError(state, t),
    isFullButton: isFullButton(buttonStyle),
    response: getResponse(state),
    isSubmitDisabled: isEditingCoupon(state) || isValidating(state),
    resetRsvpError,
    changeResponse,
    draftEvent: isDraft(event),
  }
}

const ConnectedForm = connect<ConnectedFormOwnProps, FormRuntimeProps>(mapRuntimeToProps)(Presentation)

export const Form = reduxForm<RegFormData, FormOwnProps>({
  form: REGISTRATION_FORM_ID,
  destroyOnUnmount: false,
  validate,
  onSubmitFail,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ConnectedForm)
