import {EventDetailsAlignment} from '@wix/wix-events-commons-statics'
import s from './alignment.scss'

export const getAlignmentClass = (alignment: EventDetailsAlignment) => {
  switch (alignment) {
    case EventDetailsAlignment.LEFT:
      return s.left
    case EventDetailsAlignment.RIGHT:
      return s.right
    case EventDetailsAlignment.CENTER:
    default:
      return s.center
  }
}
