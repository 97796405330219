import React from 'react'
import {Divider} from '../divider'
import {TicketProps} from './interfaces'
import {AddToWalletButton} from './add-to-wallet-button'
import s from './ticket.scss'

export const Ticket = ({
  t,
  ticket: {
    ticketNumber,
    name,
    walletPassUrl,
    guestDetails: {firstName, lastName},
  },
  showBottomDivider,
  staticsBaseUrl,
  language,
  onDownload,
}: TicketProps) => (
  <>
    <div className={s.ticketInfo}>{name}</div>
    <div className={s.ticketInfo}>{ticketNumber}</div>
    <div className={s.ticketInfo}>{`${firstName} ${lastName}`}</div>
    <AddToWalletButton
      className={s.addToWallet}
      t={t}
      language={language}
      walletPassUrl={walletPassUrl}
      staticsBaseUrl={staticsBaseUrl}
      onClick={onDownload}
    />
    {showBottomDivider && <Divider />}
  </>
)
