import {handleKeyPressEnterOrSpace} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import cn from '../../../../classnames.scss'
import s from './add-to-calendar.scss'
import {AddToCalendarProps} from '.'

export class AddToCalendar extends React.Component<AddToCalendarProps, {optionsVisible: boolean}> {
  state = {optionsVisible: false}

  onAddCalendarClick = () => {
    this.setState({optionsVisible: !this.state.optionsVisible})
    this.props.onClick()
  }

  render() {
    const {buttonText, className = '', dataHook = ''} = this.props
    const {optionsVisible} = this.state

    return (
      <div data-hook={dataHook}>
        <button
          className={classNames(className, {[s.optionsSpacing]: optionsVisible})}
          data-hook="add-to-calendar"
          aria-expanded={optionsVisible}
          onClick={this.onAddCalendarClick}
        >
          {buttonText}
        </button>
        {optionsVisible ? this.getCalendarOptions() : null}
      </div>
    )
  }

  private getCalendarOptionClasses() {
    return `${s.calendarOption} ${s.fadeInUp}`
  }

  private getCalendarOptions() {
    const {calendarLinks, t} = this.props

    return (
      <div className={classNames(s.calendarOptions, cn.formTitleColor, cn.formLabelsFont)}>
        <a
          className={this.getCalendarOptionClasses()}
          data-hook="calendar-google"
          tabIndex={0}
          aria-label={t('accessibility:a11y.calendarButtonLabel', {
            button: 'Google Calendar',
          })}
          onClick={() => window.open(calendarLinks.google)}
          onKeyPress={handleKeyPressEnterOrSpace(() => window.open(calendarLinks.google))}
        >
          Google
        </a>
        <a
          className={`${this.getCalendarOptionClasses()} ${s.delayAnimationHalf}`}
          data-hook="calendar-outlook"
          tabIndex={0}
          aria-label={t('accessibility:a11y.calendarButtonLabel', {
            button: 'Outlook',
          })}
          onClick={() => window.open(calendarLinks.ics)}
          onKeyPress={handleKeyPressEnterOrSpace(() => window.open(calendarLinks.ics))}
        >
          Outlook
        </a>
        <a
          className={`${this.getCalendarOptionClasses()} ${s.delayAnimationOne}`}
          data-hook="calendar-ical"
          tabIndex={0}
          aria-label={t('accessibility:a11y.calendarButtonLabel', {
            button: 'iCal',
          })}
          onClick={() => window.open(calendarLinks.ics)}
          onKeyPress={handleKeyPressEnterOrSpace(() => window.open(calendarLinks.ics))}
        >
          iCal
        </a>
      </div>
    )
  }
}
