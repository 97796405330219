import {isShortDateLocationVisible} from '../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {ShortDateLocationRuntimeProps} from './interfaces'
import {ShortDateLocation as Presentation} from './short-date-location'

const mapRuntime = ({state}: DetailsPageAppProps): ShortDateLocationRuntimeProps => ({
  event: state.event,
  visible: isShortDateLocationVisible(state.component.settings),
})

export const ShortDateLocation = connect<{}, ShortDateLocationRuntimeProps>(mapRuntime)(Presentation)
