export interface PriceItemProps {
  price: string
  hook: string
  label: string
  size?: PriceItemSize
}

export enum PriceItemSize {
  small,
  medium,
  big,
  extraBig,
}
