import React from 'react'
import {ModalsProps} from './interfaces'
import {OrderSummary} from './order-summary'
import {ORDER_SUMMARY, TICKETS_DOWNLOAD} from './constants'
import {TicketsDownload} from './tickets-download'

export const Modals: React.FC<ModalsProps> = ({modalType}) => {
  switch (modalType) {
    case ORDER_SUMMARY:
      return <OrderSummary />
    case TICKETS_DOWNLOAD:
      return <TicketsDownload />
    default:
      return null
  }
}
