import {withTranslation} from '@wix/yoshi-flow-editor'
import {getButtonStyle} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {OrderFailed as Presentation} from './order-failed'
import {RuntimeProps} from './interfaces'

const mapRuntime = ({state, actions: {navigateToMainPage}}: DetailsPageAppProps): RuntimeProps => ({
  buttonStyle: getButtonStyle(state.component.settings),
  navigateToMainPage,
})

export const OrderFailed = connect<{}, RuntimeProps>(mapRuntime)(withTranslation()(Presentation))
export * from './interfaces'
