import {RegFormData} from '../../types'

export const getInitialValues = (order: wix.events.ticketing.Order): Partial<RegFormData> => {
  const inputValues = order?.checkoutForm?.inputValues
  const initialValues = {}

  if (inputValues) {
    inputValues.forEach(({inputName, value, values}) => {
      initialValues[inputName] = value || values
    })
  }

  return initialValues
}
