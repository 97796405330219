import {withTranslation} from '@wix/yoshi-flow-editor'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {ModalRuntimeProps, ModalOwnProps} from './interfaces'
import {Modal as Presentation} from './modal'

const mapRuntimeToProps = ({actions: {closeNativeModal}}: DetailsPageAppProps): ModalRuntimeProps => ({
  closeModal: closeNativeModal,
})

export const Modal = withTranslation()(connect<ModalOwnProps, ModalRuntimeProps>(mapRuntimeToProps)(Presentation))
