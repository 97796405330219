import React from 'react'
import {Badge} from '../../../../badge'
import s from './sold-out-label.scss'
import {SoldOutLabelProps} from '.'

export const SoldOutLabel: React.FC<SoldOutLabelProps> = ({t, limit}) =>
  !limit && (
    <div className={s.soldOutContainer}>
      <Badge data-hook="sold-out-label">{t('mobile.ticketsPicker.soldOut')}</Badge>
    </div>
  )
