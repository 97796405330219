import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {getImageStyle} from '../../../../commons/utils/image'
import s from './event-image.scss'
import {EventImageProps} from './interfaces'

export const EventImage = ({
  containerRef,
  alt,
  container,
  image,
  opacity,
  position,
  className,
  doubleHeight,
}: EventImageProps) => {
  const {src, styles} = getImageStyle({
    image,
    container,
    opacity,
    position,
    superUpscaled: true,
  })

  return (
    <div className={classNames(s.outerContainer, className)} ref={containerRef}>
      <div className={classNames(s.imageBackground, s.sizing, {[s.double]: doubleHeight})} />
      <div className={classNames(s.imageContainer, s.sizing, {[s.double]: doubleHeight})} data-hook={DH.eventImage}>
        <img className={s.image} src={src} style={styles} alt={alt} />
      </div>
    </div>
  )
}
