import {withTranslation} from '@wix/yoshi-flow-editor'
import {getButtonStyle} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {OnlineConferencingStatusOwnProps, OnlineConferencingStatusRuntimeProps} from './interfaces'
import {OnlineConferencingStatus as Presentation} from './status'

const mapRuntime = ({
  actions: {navigateToMainPage},
  state,
}: DetailsPageAppProps): OnlineConferencingStatusRuntimeProps => ({
  buttonStyle: getButtonStyle(state.component.settings),
  navigateToMainPage,
})

export const OnlineConferencingStatus = withTranslation()(
  connect<OnlineConferencingStatusOwnProps, OnlineConferencingStatusRuntimeProps>(mapRuntime)(Presentation),
)
