import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import React from 'react'
import {Tax} from '../tax'
import {WixFee} from '../wix-fee'
import {PriceProps} from '.'

export const Price: React.FC<PriceProps> = ({t, className, ticket}) => {
  const price = getFormattedMoney(ticket.price)
  return (
    <div className={className} role="cell">
      <div data-hook="price" aria-label={t('mobile.thanks.price', {price})}>
        {price}
      </div>
      <Tax t={t} ticket={ticket} />
      <WixFee t={t} ticket={ticket} />
    </div>
  )
}
