import React from 'react'
import classNames from 'classnames'
import Download from 'wix-ui-icons-common/Download'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {DownloadPdfButtonProps} from './interfaces'
import s from './download-pdf-button.scss'

export const DownloadPdfButton = ({t, className, onClick}: DownloadPdfButtonProps) => (
  <button className={classNames(s.button, className)} data-hook={DH.DOWNLOAD_PDF} onClick={onClick}>
    {t('downloadModal_download_buttonText')}
    <div className={s.downloadIcon}>
      <Download size="24px" />
    </div>
  </button>
)
