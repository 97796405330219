import {getCurrency, getTaxConfig} from '@wix/wix-events-commons-statics'
import {connect} from '../../runtime-context/context'
import {getOrderedTickets} from '../../../selectors/placed-order'
import {DetailsPageAppProps} from '../../app/interfaces'
import {TicketsSummaryOwnProps, TicketsSummaryRuntimeProps} from './interfaces'
import {TicketsSummary as Presentation} from './tickets-summary'

const mapRuntime = ({state}: DetailsPageAppProps): TicketsSummaryRuntimeProps => {
  const {tickets, selectedTickets, event} = state
  const orderedTickets = getOrderedTickets(selectedTickets, tickets)
  const taxConfig = getTaxConfig(event)

  return {
    tickets: orderedTickets,
    currency: getCurrency(event),
    taxConfig,
  }
}

export const TicketsSummary = connect<TicketsSummaryOwnProps, TicketsSummaryRuntimeProps>(mapRuntime)(Presentation)

export * from './interfaces'
