import {getEventDescription, getEventMainImage, getEventTitle} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMembersVisibleWithGuests} from '../../selectors/members'
import {isMoreDatesButtonVisible, isMoreDatesLabelVisible} from '../../selectors/occurrences'
import {
  getContentAlignment,
  getHeaderAlignment,
  getText,
  isScheduleVisible,
  isSideBySideHeaderLayout,
  isSocialShareVisible,
} from '../../selectors/settings'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {
  isAboutSectionVisible,
  isDescriptionVisible,
  isMapVisible,
  isSecondRegistrationButtonVisible,
} from '../../selectors/event'
import {hasScheduleItems} from '../../selectors/schedule'
import {showGroupActivity} from '../../selectors/groups'
import {EventDetailsMobile as Presentation} from './event-details-mobile'
import {EventDetailsOwnProps, EventDetailsRuntimeProps} from './interfaces'

const mapRuntime = ({
  state,
  actions: {handleRSVP, detailsPageLoaded},
}: DetailsPageAppProps): EventDetailsRuntimeProps => {
  const event = state.event
  const componentSettings = state.component.settings
  const scheduleVisible = isScheduleVisible(componentSettings) && hasScheduleItems(state)
  return {
    eventTitle: getEventTitle(event),
    eventDescription: getEventDescription(event),
    mainImage: getEventMainImage(event),
    headerAlignment: getHeaderAlignment(componentSettings),
    contentAlignment: getContentAlignment(componentSettings),
    descriptionVisible: isDescriptionVisible(state),
    timeAndLocationTitleText: getText('timeAndLocationTitleText', componentSettings),
    membersVisible: isMembersVisibleWithGuests(state),
    mapVisible: isMapVisible(state),
    aboutSectionVisible: isAboutSectionVisible(state),
    isSecondRegistrationButtonVisible: isSecondRegistrationButtonVisible(state),
    socialShareVisible: isSocialShareVisible(componentSettings),
    handleRSVP,
    detailsPageLoaded,
    scheduleVisible,
    sideBySideHeaderLayout: isSideBySideHeaderLayout(componentSettings),
    moreDatesVisible: isMoreDatesLabelVisible(state),
    moreDatesButtonVisible: isMoreDatesButtonVisible(state),
    groupVisible: showGroupActivity(state),
  }
}

export const EventDetailsMobile = withTranslation()(
  connect<EventDetailsOwnProps, EventDetailsRuntimeProps>(mapRuntime)(Presentation),
)
export * from './interfaces'
