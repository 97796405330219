import React from 'react'
import classNames from 'classnames'
import {DividerProps} from './interfaces'
import s from './divider.scss'

export const Divider = ({full}: DividerProps) => (
  <div className={s.container}>
    <div className={classNames(s.divider, {[s.fullDivider]: full})} />
  </div>
)
