import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {AddToWalletButtonProps, TRANSLATED_ASSETS} from './interfaces'
import s from './add-to-wallet-button.scss'

export const AddToWalletButton = ({
  t,
  className,
  walletPassUrl,
  staticsBaseUrl,
  language,
  onClick,
}: AddToWalletButtonProps) => {
  const languageCode = TRANSLATED_ASSETS.includes(language) ? language : 'en'
  const addToAppleWalletIconUrl = `${staticsBaseUrl}/assets/add-to-wallet-icons/${languageCode}.svg`

  return (
    <a className={classNames(s.button, className)} href={walletPassUrl} target="_blank" onClick={onClick}>
      <img
        data-hook={DH.DOWNLOAD_APPLE_WALLET}
        src={addToAppleWalletIconUrl}
        alt={t('downloadModal_addToWalletButton_altText')}
      ></img>
    </a>
  )
}
