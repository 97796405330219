import classNames from 'classnames'
import React from 'react'
import c from '../../../../classnames.scss'
import {Price} from '../price'
import {TicketDescription} from '../ticket-description'
import s from './ticket-name.scss'
import {TicketNameProps} from '.'

export const TicketName: React.FC<TicketNameProps> = ({t, ticket}) => {
  const {description, id, name} = ticket
  return (
    <>
      <div
        className={classNames(c.evTicketNameColor, c.evTicketNameFont)}
        data-hook="name"
        role="cell"
        aria-label={`${t('ticketsThankYou.ticketName')}: ${name}`}
      >
        {name}
      </div>
      <Price t={t} className={classNames(s.ticketNamePrice, c.evTextFont, c.evTextColor)} ticket={ticket} />
      {description && (
        <div className={s.description}>
          <TicketDescription ticketDefId={id} description={description} />
        </div>
      )}
    </>
  )
}
