import {getEventTitle, getFormattedLocation, isTicketed} from '@wix/wix-events-commons-statics'
import {getDatesInformation} from '../../selectors/dates'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {SummaryOwnProps, SummaryRuntimeProps} from './interfaces'
import {Summary as Presentation} from './summary'

const mapRuntimeToProps = ({state}: DetailsPageAppProps): SummaryRuntimeProps => {
  const event = state.event
  const ticketedEvent = isTicketed(event)

  return {
    title: getEventTitle(event),
    date: getDatesInformation(state).fullDate,
    location: getFormattedLocation(event),
    ticketedEvent,
  }
}

export const Summary = connect<SummaryOwnProps, SummaryRuntimeProps>(mapRuntimeToProps)(Presentation)
