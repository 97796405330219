import {getAboutText} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {isEditor, isMobile} from '../../../../../commons/selectors/environment'
import {getButtonStyle, getTexts} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {showPaidPlans} from '../../../selectors/paid-plans'
import {showGroupActivity} from '../../../selectors/groups'
import {AboutSection as Presentation} from './about-section'
import {AboutSectionOwnProps, AboutSectionRuntimeProps} from './interfaces'

export const mapRuntimeToProps = ({state, host}: DetailsPageAppProps): AboutSectionRuntimeProps => ({
  updateLayout: host.updateLayout,
  aboutTitleText: getTexts(state.component.settings).aboutTitleText,
  aboutText: getAboutText(state.event),
  readMoreText: getTexts(state.component.settings).readMoreText,
  readLessText: getTexts(state.component.settings).readLessText,
  buttonStyle: getButtonStyle(state.component.settings),
  isEditor: isEditor(state),
  isMobile: isMobile(state),
  readMoreButtonExtraMargin: showPaidPlans(state) && !showGroupActivity(state),
})

export const AboutSection = connect<AboutSectionOwnProps, AboutSectionRuntimeProps>(mapRuntimeToProps)(
  withTranslation()(Presentation),
)
export * from './interfaces'
