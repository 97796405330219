import {withTranslation} from '@wix/yoshi-flow-editor'
import {isTicketed} from '@wix/wix-events-commons-statics'
import {connect} from '../../runtime-context/context'
import {DetailsPageAppProps} from '../../app/interfaces'
import {getUpdatesCount} from '../../../selectors/groups'
import {GroupActivity as Presentation} from './group-activity'
import {GroupActivityRuntimeProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): GroupActivityRuntimeProps => ({
  ticketed: isTicketed(state.event),
  updatesCount: getUpdatesCount(state),
})

export const GroupActivity = connect<{}, GroupActivityRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
