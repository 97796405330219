import {withTranslation} from '@wix/yoshi-flow-editor'
import {getModalType} from '../../selectors/modals'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {ModalsRuntimeProps} from './interfaces'
import {Modals as Presentation} from './modals'

const mapRuntime = ({state}: DetailsPageAppProps): ModalsRuntimeProps => ({
  modalType: getModalType(state),
})

export const Modals = connect<any, ModalsRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
