import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../../../../classnames.scss'
import {MembershipSelectorOptionProps} from './interface'
import s from './membership-selector-option.scss'

const textStyles = classNames(s.text, c.formSummaryBody, c.formSummaryColor)

export const MembershipSelectorOption: React.FC<MembershipSelectorOptionProps> = ({
  title,
  subtitle,
  selected,
  disabled,
  optionProps,
}) => (
  <div
    className={classNames(s.membershipOptionContainer, c.membershipOptionContainer, {
      [c.membershipOptionContainerActive]: selected,
    })}
    {...optionProps}
  >
    <div
      data-hook={DH.MEMBERSHIP_SELECTOR_OPTION_TITLE}
      className={classNames(textStyles, {[c.formSummaryColorSecondary]: disabled})}
    >
      {title}
    </div>
    {subtitle && (
      <div data-hook={DH.MEMBERSHIP_SELECTOR_OPTION_SUBTITLE} className={classNames(textStyles, s.subtitle)}>
        {subtitle}
      </div>
    )}
  </div>
)
