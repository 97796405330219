import React from 'react'
import {FormButton} from '../../form-button'
import {REGISTRATION_FORM_ID} from '../../form/constants'
import {TicketsDetailsProps} from './interfaces'
import {TicketDetails} from './ticket-details'
import s from './tickets-details.scss'

export class TicketsDetails extends React.Component<TicketsDetailsProps> {
  toggleExpandedTicket = (index: number) =>
    this.props.setExpandedTicketIndex(this.props.expandedTicketIndex === index ? -1 : index)

  expandNextTicket = () => this.props.nextFormClicked()

  collapseTickets = () => this.props.setExpandedTicketIndex(-1)

  submit = () => {
    this.props.onSubmit(this.props.details)
    this.collapseTickets()
  }

  render() {
    const {editing, selectedTickets, buttonStyle, messages, t, allTicketDetailsValid, expandedTicketIndex} = this.props

    return (
      <div className={s.container}>
        {selectedTickets.map((ticket, index) => (
          <TicketDetails
            key={index}
            formId={`${REGISTRATION_FORM_ID}${index}`}
            index={index}
            ticket={ticket}
            editable={editing}
            expanded={index === expandedTicketIndex}
            hideButton={index === selectedTickets.length - 1}
            onExpand={() => this.toggleExpandedTicket(index)}
            onSubmit={this.expandNextTicket}
            t={t}
          />
        ))}
        {editing && (
          <div className={s.button}>
            <FormButton
              text={messages.checkout.submitActionLabel || messages.rsvp.submitActionLabel}
              buttonStyle={buttonStyle}
              disabled={!allTicketDetailsValid}
              onClick={this.submit}
            />
          </div>
        )}
      </div>
    )
  }
}
