import {TFunction} from '@wix/yoshi-flow-editor'

export interface AddToWalletButtonProps {
  t: TFunction
  className?: string
  walletPassUrl: string
  staticsBaseUrl: string
  language: string
  onClick: () => void
}

export const TRANSLATED_ASSETS = [
  'ar',
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'fi',
  'fr',
  'he',
  'hu',
  'id',
  'it',
  'ja',
  'ko',
  'lt',
  'ms',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'sv',
  'th',
  'tr',
  'vi',
  'zh',
]
