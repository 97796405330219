import classNames from 'classnames'
import React from 'react'
import c from '../../../classnames.scss'
import s from './radio.scss'
import {RadioProps} from '.'

export class Radio extends React.Component<RadioProps> {
  onChange = () => {
    this.props.onChange(this.props.value)
  }

  render() {
    const {name, value, checked, dataHook = 'radio-label'} = this.props
    const id = `${name}-${value}`

    return (
      <div role="radio" aria-checked={checked} onClick={this.onChange}>
        <input
          className={s.input}
          data-hook="radio-input"
          name={name}
          type="radio"
          id={id}
          value={value}
          checked={checked}
          onChange={this.onChange}
        />
        <label className={s.wrapper} htmlFor={id}>
          <span className={classNames(c.formBorderColor, s.radio)} />
          <div className={classNames(s.label, c.formLabelsFont, c.formLabelsColor)} data-hook={dataHook}>
            {value}
          </div>
        </label>
      </div>
    )
  }
}
