import React from 'react'
import {RegistrationButtonSkin} from '../../registration-buttons/registration-button-skin'
import {Container} from '../common/container'
import {Title} from '../common/title'
import {LinkExpiredProps} from './interfaces'

export const LinkExpired = ({t, buttonStyle, navigateToMainPage}: LinkExpiredProps) => (
  <Container dataHook="link-expired">
    <div style={{textAlign: 'center'}}>
      <Title text={t('linkExpired_title')} />
      <RegistrationButtonSkin
        text={t('linkExpired_buttonText')}
        buttonStyle={buttonStyle}
        onClick={() => navigateToMainPage()}
      />
    </div>
  </Container>
)
