import React, {useRef} from 'react'
import classNames from 'classnames'
import {getImageStyle} from '../../../../../commons/utils/image'
import s from './group-image.scss'

interface GroupImageProps {
  image: wix.common.Image
  alt: string
  className?: string
}

export const GroupImage = ({image, alt, className}: GroupImageProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const container = ref.current?.getBoundingClientRect()

  return (
    <div className={classNames(className, s.root)} ref={ref}>
      {container ? <Image container={container} image={image} alt={alt} /> : null}
    </div>
  )
}

const Image = ({container, image, alt}) => {
  const {src, styles} = getImageStyle({
    image,
    container,
  })
  return <img src={src} style={styles} alt={alt} />
}
