import React from 'react'
import {RegistrationLayoutProps} from './interfaces'
import s from './registration-layout.scss'

export const RegistrationLayout: React.FC<RegistrationLayoutProps> = ({Left, Right}) => (
  <div className={s.container}>
    <div className={s.left}>{Left}</div>
    <div className={s.right}>{Right}</div>
  </div>
)
