import {withTranslation} from '@wix/yoshi-flow-editor'
import {connect} from '../runtime-context/context'
import {getGroupImage, getGroupTitle, isGroupPrivate} from '../../selectors/groups'
import {DetailsPageAppProps} from '../app/interfaces'
import {GroupCardRuntimeProps, GroupCardOwnProps} from './interfaces'
import {GroupCard as Presentation} from './group-card'

export const mapRuntime = ({state, actions: {joinGroup}}: DetailsPageAppProps): GroupCardRuntimeProps => ({
  title: getGroupTitle(state),
  image: getGroupImage(state),
  isPrivate: isGroupPrivate(state),
  joinGroup,
})

export const GroupCard = withTranslation()(connect<GroupCardOwnProps, GroupCardRuntimeProps>(mapRuntime)(Presentation))
