import classNames from 'classnames'
import React from 'react'
import {getFormClassName} from '../../selectors/form'
import c from '../classnames.scss'
import {FormButton} from '../form-button'
import {RegistrationLayout} from '../registration-layout'
import {Summary} from '../summary'
import {Step, FormSteps} from '../form-steps'
import {RsvpFormProps} from './interfaces'
import s from './rsvp-form.scss'
import {RsvpFormContainer} from './container'

export class RsvpForm extends React.Component<RsvpFormProps> {
  componentDidMount() {
    this.props.formPageLoaded()
  }

  render() {
    const {
      closedRegistrationMessage,
      registrationClosed,
      buttonStyle,
      memberRsvpReady,
      memberRsvpExists,
      ticketedEvent,
      shouldNavigateToForm,
      t,
      navigateToMainPage,
    } = this.props

    if (!memberRsvpReady || (memberRsvpExists && !ticketedEvent && !shouldNavigateToForm)) {
      return null
    }

    const containerClass = classNames(c.formBackgroundColor, getFormClassName(buttonStyle))

    if (registrationClosed) {
      return (
        <div className={classNames(containerClass, s.closedRegistrationMessageContainer)}>
          <div className={classNames(s.closedRegistrationMessage, c.formTitleFont, c.formTitleColor)}>
            {closedRegistrationMessage.message}
          </div>
          <FormButton
            text={closedRegistrationMessage.exploreEventsActionLabel}
            buttonStyle={buttonStyle}
            width="auto"
            onClick={() => navigateToMainPage()}
          />
        </div>
      )
    }

    return (
      <RsvpFormContainer t={t}>
        {(steps: Step[], currentStep: string) => (
          <div className={containerClass}>
            <RegistrationLayout
              Right={<Summary t={t} />}
              Left={<FormSteps currentStep={currentStep} steps={steps} t={t} />}
            />
          </div>
        )}
      </RsvpFormContainer>
    )
  }
}
