import {getCoordinates} from '@wix/wix-events-commons-statics'
import {getLanguage, isMobile} from '../../../../../commons/selectors/environment'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {Map as Presentation} from './map'

export interface MapProps extends MapRuntimeProps {}

export interface MapRuntimeProps {
  coordinates: wix.events.MapCoordinates
  mobile: boolean
  language: string
  instance: string
}

const mapRuntime = ({state}: DetailsPageAppProps): MapRuntimeProps => ({
  coordinates: getCoordinates(state.event),
  mobile: isMobile(state),
  language: getLanguage(state),
  instance: state.instance.instance,
})

export const Map = connect<{}, MapRuntimeProps>(mapRuntime)(Presentation)
