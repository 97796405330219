import {getImageOpacity, getImagePosition} from '../../selectors/settings'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {EventImage as Presentation} from './event-image'
import {EventImageOwnProps, EventImageStateProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): EventImageStateProps => {
  const {event, component} = state
  const {settings} = component

  return {
    image: event.mainImage,
    alt: event.title,
    position: getImagePosition(settings),
    opacity: getImageOpacity(settings),
  }
}

export const EventImage = connect<EventImageOwnProps, EventImageStateProps>(mapRuntime)(Presentation)
