import {getFormattedPrice, getFormattedMoney} from '@wix/wix-events-commons-statics'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {InvoiceBreakdown} from '../../invoice-breakdown'
import {SummaryDivider} from '../summary-divider'
import SummaryStyles from '../summary.scss'
import {OrderedTicket} from '../../../types'
import c from '../../classnames.scss'
import s from './tickets-summary.scss'
import {TicketsSummaryProps} from './index'

export const TicketsSummary: React.FC<TicketsSummaryProps> = ({currency, tickets, t}) => {
  if (!tickets.length) {
    return null
  }

  const getTicketAriaLabel = ticket => {
    const name = `${t('ticketsThankYou.ticketName')}: ${ticket.name}`
    const price = t('mobile.thanks.price', {price: getFormattedPrice(ticket.price * ticket.orderedCount, currency)})
    const qty = t('mobile.thanks.quantity', {quantity: ticket.orderedCount})
    return `${name}, ${price}, ${qty}`
  }

  return (
    <div className={c.formSummaryBody} data-hook={DH.TICKETS_SUMMARY}>
      <div className={SummaryStyles.summaryTicketsContainer}>
        <SummaryDivider />
        {tickets.map((orderedTicket: OrderedTicket) => (
          <div key={orderedTicket.id} data-hook={DH.TICKET_WRAPPER}>
            <div className={s.ticket} data-hook={DH.TICKET} aria-label={getTicketAriaLabel(orderedTicket)}>
              <div className={s.name} data-hook={DH.TICKET_NAME}>
                {orderedTicket.name}
              </div>
              <div className={s.singlePrice}>
                {t('mobile.thanks.price', {price: getFormattedMoney(orderedTicket.price)})}
              </div>
              <div className={s.row}>
                <div className={s.quantity} data-hook={DH.TICKET_QUANTITY}>
                  {t('mobile.thanks.quantity', {quantity: orderedTicket.orderedCount})}
                </div>
                <div className={s.price} data-hook={DH.TICKET_TOTAL_PRICE}>
                  {getFormattedPrice(Number(orderedTicket.price.amount) * orderedTicket.orderedCount, currency)}
                </div>
              </div>
            </div>
            <SummaryDivider />
          </div>
        ))}
      </div>
      <InvoiceBreakdown t={t} />
    </div>
  )
}
