import * as React from 'react'

export interface ErrorProps extends React.SVGAttributes<SVGElement> {
  size?: string | number
}
const Error = ({size, ...props}: ErrorProps) => (
  <svg viewBox="0 0 24 24" fill="currentColor" width={size || '24'} height={size || '24'} {...props}>
    <path
      d="M12 20.5a8.5 8.5 0 110-17 8.5 8.5 0 010 17zm0 0a8.5 8.5 0 100-17 8.5 8.5 0 000 17zM12 7.02v6.983M12 16.01v.977"
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Error
