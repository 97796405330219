import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../../../classnames.scss'
import s from './checkout-summary.scss'
import {CheckoutSummaryProps} from './interfaces'

export const CheckoutSummary: React.FC<CheckoutSummaryProps> = ({invoice, onViewAllItems, t}) => (
  <div className={classNames(s.container, c.formSummaryBox, c.formSummaryColor)}>
    <div className={classNames(s.spreadRow, s.titleRow, c.formSummaryBody)}>
      <div className={s.title}>{t('mobile.couponForm.title')}</div>
      <div className={s.viewItems} onClick={onViewAllItems} data-hook={DH.SHOW_SUMMARY_BUTTON}>
        {t('mobile.summary.showTickets')}
      </div>
    </div>
    <div data-hook={DH.CHECKOUT_SUMMARY_TOTAL} className={classNames(s.spreadRow, s.total, c.formSummaryBody)}>
      <div>{t('mobile.ticketsPicker.total')}</div>
      <div>{getFormattedMoney(invoice.grandTotal)}</div>
    </div>
  </div>
)
