import {getInputControlMeta} from '@wix/wix-events-commons-statics'
import React from 'react'
import {Label} from '../../commons/label'
import {Select} from '../../commons/select'
import fs from '../../fields.scss'
import {DropdownProps} from '.'

export class Dropdown extends React.Component<DropdownProps> {
  options: string[] = getInputControlMeta(this.props.control).options

  componentDidMount() {
    if (!this.getValue()) {
      this.onChange(this.options[0])
    }
  }

  onChange = (value: string) => this.props.input.onChange(value)

  getValue = () => this.props.input.value

  render() {
    const {label} = getInputControlMeta(this.props.control)

    return (
      <div className={fs.field}>
        <Label classes={fs.customCaption} dataHook="dropdown-caption" required={false} caption={label} />
        <Select options={this.options} value={this.getValue()} ariaLabel={label} onChange={this.onChange} />
      </div>
    )
  }
}
