import classNames from 'classnames'
import React from 'react'
import {getPlanBenefitText, planHasRemainingCredits} from '../../../../selectors/paid-plans'
import c from '../../../classnames.scss'
import {MembershipSelectorOptionsProps} from './interface'
import {MembershipSelectorOption} from './membership-selector-option'
import s from './membership-selector-options.scss'

export const MembershipSelectorOptions = React.forwardRef(
  (
    {t, planOptions, isMobile, optionsProps: {containerProps, getOptionProps}}: MembershipSelectorOptionsProps,
    ref: React.LegacyRef<HTMLDivElement>,
  ) => (
    <div
      ref={ref}
      className={classNames(c.membershipSelectorOptions, s.optionsContainer, {
        [s.optionsContainerDesktop]: !isMobile,
      })}
      {...containerProps}
    >
      {planOptions.map((plan, index) => {
        const noPlan = !index
        const {key, selected, ...optionProps} = getOptionProps(index)
        const expired = !noPlan && !planHasRemainingCredits(plan)

        return (
          <MembershipSelectorOption
            key={key}
            selected={selected}
            disabled={expired}
            title={noPlan ? t('membership_not_selected') : plan.name}
            subtitle={noPlan ? undefined : getPlanBenefitText(plan, t, true)}
            optionProps={optionProps}
          />
        )
      })}
    </div>
  ),
)
