import {focusElement} from '@wix/wix-events-commons-statics'
import {InputControlType} from '@wix/events-types'
import {FormErrors} from 'redux-form'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Address} from './desktop/address'
import {Checkbox} from './desktop/checkbox'
import {Date} from './desktop/date'
import {Dropdown} from './desktop/dropdown'
import {GuestsControl} from './desktop/guests-control'
import {Input} from './desktop/input'
import {Name} from './desktop/name'
import {Radio} from './desktop/radio'
import {Textarea} from './desktop/textarea'

export const FORM_DESKTOP_COMPONENTS = {
  [InputControlType.NAME]: Name,
  [InputControlType.INPUT]: Input,
  [InputControlType.TEXTAREA]: Textarea,
  [InputControlType.DROPDOWN]: Dropdown,
  [InputControlType.RADIO]: Radio,
  [InputControlType.CHECKBOX]: Checkbox,
  [InputControlType.GUEST_CONTROL]: GuestsControl,
  [InputControlType.ADDRESS_FULL]: Address,
  [InputControlType.ADDRESS_SHORT]: Address,
  [InputControlType.DATE]: Date,
}

export const isFormValid = (errors: FormErrors) => !Object.keys(errors).some(key => Boolean(errors[key]))

export const onSubmitFail = () => {
  focusElement({selector: `${DH.hookToAttributeSelector(DH.FORM_INVALID_FIELD)} input`, canFocus: true})
}
