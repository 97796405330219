import classNames from 'classnames'
import React from 'react'
import DownIcon from 'wix-ui-icons-common/ChevronDown'
import UpIcon from 'wix-ui-icons-common/ChevronUp'
import ConfirmIcon from 'wix-ui-icons-common/Confirm'
import TagIcon from 'wix-ui-icons-common/Tag'
import c from '../../../classnames.scss'
import {MembershipSelectorInputProps} from './interfaces'
import s from './membership-selector-input.scss'

export const MembershipSelectorInput: React.FC<MembershipSelectorInputProps> = ({
  selectedPlan,
  readOnly,
  inputProps: {
    containerProps: {expanded, onClick, onKeyDown, ...containerProps},
    valueProps,
  },
  t,
}) => {
  const isPlanSelected = Boolean(selectedPlan)
  const StatusIcon = isPlanSelected ? ConfirmIcon : TagIcon
  const ChevronIcon = expanded ? UpIcon : DownIcon

  return (
    <div
      className={classNames(s.inputContainer, c.formSummaryBox, {
        [c.formSummaryBoxBorderColorActive]: expanded || isPlanSelected,
      })}
      {...containerProps}
      onClick={readOnly ? null : onClick}
      onKeyDown={readOnly ? null : onKeyDown}
    >
      <StatusIcon className={s.icon} size="24px" />
      <div className={classNames(s.text, c.formSummaryBody, c.formSummaryColor, s.selectedText)} {...valueProps}>
        {isPlanSelected ? selectedPlan.name : t('membership_not_selected')}
      </div>
      {!readOnly && <ChevronIcon className={s.icon} size="24px" />}
    </div>
  )
}
