import React from 'react'
import {RegistrationButtonSkin} from '../../registration-buttons/registration-button-skin'
import {Container} from '../common/container'
import {Subtitle} from '../common/subtitle'
import {Title} from '../common/title'
import {OrderCanceledProps} from './interfaces'

export const OrderCanceled = ({t, buttonStyle, navigateToDetails}: OrderCanceledProps) => (
  <Container dataHook="online-conferencing-disabled">
    <div style={{textAlign: 'center'}}>
      <Title text={t('paymentError.paymentCanceledTitle')} />
      <Subtitle lines={[t('paymentError.paymentCanceledDescription')]} />
      <RegistrationButtonSkin
        text={t('paymentError.buttonText')}
        buttonStyle={buttonStyle}
        onClick={() => navigateToDetails()}
      />
    </div>
  </Container>
)
