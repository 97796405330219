import {withTranslation} from '@wix/yoshi-flow-editor'
import {getButtonStyle} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {OrderCanceledOwnProps, OrderCanceledRuntimeProps} from './interfaces'
import {OrderCanceled as Presentation} from './order-canceled'

const mapRuntime = ({actions: {navigateToDetails}, state}: DetailsPageAppProps): OrderCanceledRuntimeProps => ({
  buttonStyle: getButtonStyle(state.component.settings),
  navigateToDetails,
})

export const OrderCanceled = withTranslation()(
  connect<OrderCanceledOwnProps, OrderCanceledRuntimeProps>(mapRuntime)(Presentation),
)
