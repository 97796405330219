import {isRegistrationClosedVisible, getEventTicketLimitPerOrder} from '@wix/wix-events-commons-statics'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {getSelectedTicketsQuantity, getSelectedTicketQuantity} from '../../../../selectors/selected-tickets'
import {getButtonStyle, isHollowButton} from '../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {TicketOwnProps, TicketRuntimeProps} from './interfaces'
import {Ticket as MobilePresentation} from './mobile-ticket'
import {Ticket as Presentation} from './ticket'

const mapRuntime = ({state}: DetailsPageAppProps, {ticket: {id}}: TicketOwnProps): TicketRuntimeProps => {
  const {
    selectedTickets,
    component: {settings},
    event,
  } = state
  const maxTicketsPerReservation = getEventTicketLimitPerOrder(event)
  const selectedQuantity = getSelectedTicketQuantity(selectedTickets, id)
  const availableTicketsQuantity = maxTicketsPerReservation - getSelectedTicketsQuantity(selectedTickets)

  return {
    selectedQuantity,
    isHollowButton: isHollowButton(getButtonStyle(settings)),
    availableTicketsQuantity,
    registrationClosed: isRegistrationClosedVisible(event),
    isMobile: isMobile(state),
    maxTicketsPerReservation,
  }
}

export const Ticket = connect<TicketOwnProps, TicketRuntimeProps>(mapRuntime)(Presentation)
export const MobileTicket = connect<TicketOwnProps, TicketRuntimeProps>(mapRuntime)(MobilePresentation)
export * from './interfaces'
