import React from 'react'
import {FormStepsProps} from './interfaces'
import {Step} from './step'

export const FormSteps: React.FC<FormStepsProps> = ({steps, currentStep, t}) => (
  <div>
    {steps.map(({key, title, completed, renderStep, onEdit}, index) => (
      <Step
        key={key}
        stepKey={key}
        title={title}
        index={index + 1}
        editing={currentStep === key}
        completed={completed}
        indexVisible={steps.length > 1}
        dividerVisible={!!index}
        renderStep={renderStep}
        onEdit={onEdit}
        t={t}
      />
    ))}
  </div>
)
