import {StyleParamType, createStylesParams} from '@wix/yoshi-flow-editor/tpa-settings'

type IStylesParams = {
  headerBorderWidth: StyleParamType.Number
  evTicketDividerSize: StyleParamType.Number
  rsvpButtonBorderWidth: StyleParamType.Number
  rsvpButtonBorderRadius: StyleParamType.Number
  rsvpButtonRoundedBorderRadius: StyleParamType.Number
  formButtonBorderWidth: StyleParamType.Number
  formButtonBorderRadius: StyleParamType.Number
  formButtonRoundedBorderRadius: StyleParamType.Number
  formSummaryBoxBorderSize: StyleParamType.Number
}

export default createStylesParams<IStylesParams>({
  headerBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  evTicketDividerSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  rsvpButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  rsvpButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  rsvpButtonRoundedBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  formButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  formButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  formButtonRoundedBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  formSummaryBoxBorderSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
})
