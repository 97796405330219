import {getTaxConfig} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {WixFeeOwnProps, WixFeeRuntimeProps} from './interfaces'
import {WixFee as Presentation} from './wix-fee'

export const mapRuntime = ({state: {event}}: DetailsPageAppProps): WixFeeRuntimeProps => ({
  taxConfig: getTaxConfig(event),
})

export const WixFee = connect<WixFeeOwnProps, WixFeeRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
