import {connect} from '../runtime-context/context'
import {getInvoice} from '../../selectors/placed-order'
import {DetailsPageAppProps} from '../app/interfaces'
import {InvoiceBreakdown as Presentation} from './invoice-breakdown'
import {InvoiceBreakdownRuntimeProps, InvoiceBreakdownOwnProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): InvoiceBreakdownRuntimeProps => ({
  invoice: getInvoice(state),
})

export * from './interfaces'
export const InvoiceBreakdown = connect<InvoiceBreakdownOwnProps, InvoiceBreakdownRuntimeProps>(mapRuntime)(
  Presentation,
)
