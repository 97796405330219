import {isEditor, isFullWidth} from '../../../../../../../commons/selectors/environment'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {Image as Presentation} from './image'
import {ImageRuntimeProps} from './interfaces'

const mapRuntime = ({state, host}: DetailsPageAppProps): ImageRuntimeProps => {
  const {event} = state
  return {
    mainImage: event.mainImage,
    crop: !isEditor(state),
    fullWidth: isFullWidth(state, host.dimensions),
  }
}

export const Image = connect<{}, ImageRuntimeProps>(mapRuntime)(Presentation)
