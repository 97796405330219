import {focusElement, BI_ORIGINS} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {SocialShare} from '../../social-share'
import {Container} from '../common/container'
import {Subtitle} from '../common/subtitle'
import {Title} from '../common/title'
import c from '../../classnames.scss'
import {SuccessActions} from '../common/success-actions'
import {GroupCard} from '../../group-card'
import {ThankYouMessagesProps, ThankYouMessagesState} from './interfaces'
import s from './thank-you-messages.scss'

export class ThankYouMessages extends React.Component<ThankYouMessagesProps, ThankYouMessagesState> {
  state = {showCalendarOptions: false}

  componentDidMount() {
    this.props.thankYouPageLoaded()
    focusElement({selector: DH.hookToAttributeSelector(DH.THANK_YOU_MESSAGE_TITLE)})
  }

  render() {
    const {messages, dateTbd, t, isMember, showGroup, navigateToMainPage} = this.props
    const {title, message, addToCalendarActionLabel, shareActionLabel} = messages.confirmation

    return (
      <Container dataHook={DH.THANK_YOU_MESSAGE}>
        <div className={classNames(s.container, c.thankYouMessages, c.formLabelsFont, c.formTitleColor)}>
          <Title text={title} dataHook={DH.THANK_YOU_MESSAGE_TITLE} />
          {message ? (
            <Subtitle
              lines={[message, isMember ? t('members.rsvpThankYouMessage') : null]}
              dataHook={DH.THANK_YOU_MESSAGE_NOTIFICATION}
            />
          ) : null}
          <button className={s.backToSite} data-hook={DH.BACK_TO_SITE} onClick={() => navigateToMainPage()}>
            {t('backToSite')}
          </button>
          <SuccessActions
            t={t}
            className={s.successActions}
            downloadTicketsVisible={false}
            addToCalendarVisible={!dateTbd && Boolean(addToCalendarActionLabel)}
            calendarButtonText={addToCalendarActionLabel}
          />
          {showGroup ? <GroupCard className={s.group} /> : null}
          {shareActionLabel ? (
            <div className={s.share}>
              <div data-hook={DH.SOCIAL_SHARE_TITLE}>{shareActionLabel}</div>
              <div className={s.shareButtons}>
                <SocialShare t={t} page={BI_ORIGINS.THANK_YOU_PAGE} />
              </div>
            </div>
          ) : null}
        </div>
      </Container>
    )
  }
}
