import React from 'react'
import classNames from 'classnames'
import cl from '../classnames.scss'
import {GroupCardProps} from './interfaces'
import s from './group-card.scss'
import {JoinButton} from './join-button'
import {GroupImage} from './group-image'

export const GroupCard = ({className, title, isPrivate, image, joinGroup, t}: GroupCardProps) => (
  <div className={classNames(s.root, className)}>
    {image ? <GroupImage image={image} alt={title} className={s.image} /> : null}
    <div className={s.description}>
      <h4 className={classNames(s.title, cl.evTextFont)}>{title}</h4>
      <div className={classNames(s.privacy, cl.evTextFont)}>
        {isPrivate ? t('groups.privacy.private') : t('groups.privacy.public')}
      </div>
    </div>
    <div className={s.cta}>
      <JoinButton className={s.button} onClick={() => joinGroup()}>
        {t('groups.joinButton')}
      </JoinButton>
    </div>
  </div>
)
