import {TFunction} from '@wix/yoshi-flow-editor'
import {SocialNetworks} from '@wix/wix-events-commons-statics'

const encodeParams = (params: {[key: string]: string}) =>
  Object.entries(params)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&')

export const shareEvent = (eventUrl: string, platform: SocialNetworks, t: TFunction) => {
  const params = encodeParams({
    [platform === SocialNetworks.Facebook ? 'quote' : 'text']: getTitle(platform, t),
  })

  const url = getUrl(platform, eventUrl, params)

  window.open(url, '_blank', 'top=200, left=200, width=700, height=500')
}

const getUrl = (platform: SocialNetworks, eventUrl: string, params: string) => {
  switch (platform) {
    case SocialNetworks.Facebook:
    default:
      return `https://www.facebook.com/sharer/sharer.php?u=${eventUrl}&${params}`
    case SocialNetworks.Twitter:
      return `https://twitter.com/intent/tweet?url=${eventUrl}&${params}`
    case SocialNetworks.LinkedIn:
      // Other params than url does not work anymore with linkedin sharing: https://stackoverflow.com/a/61583006
      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(eventUrl)}`
  }
}

const getTitle = (platform: SocialNetworks, t: TFunction) => {
  switch (platform) {
    case SocialNetworks.Facebook:
    default:
      return t('facebookShareTitle')
    case SocialNetworks.LinkedIn:
      return t('linkedInShareTitle')
    case SocialNetworks.Twitter:
      return t('twitterShareTitle')
  }
}
