import {getCalendarLinks} from '../../../../selectors/calendar-links'
import {getFormButtonStyle} from '../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {getTicketsPdf} from '../../../../selectors/placed-order'
import {SuccessActions as Presentation} from './success-actions'
import {SuccessActionsRuntimeProps, SuccessActionsOwnProps} from './interfaces'

const mapRuntime = (
  {actions: {downloadTickets, openTicketsDownloadModal, addToCalendarClicked}, state}: DetailsPageAppProps,
  {downloadTicketsVisible}: SuccessActionsOwnProps,
): SuccessActionsRuntimeProps => ({
  mobile: isMobile(state),
  ticketsPdf: downloadTicketsVisible ? getTicketsPdf(state) : undefined,
  formButtonStyle: getFormButtonStyle(state.component.settings),
  calendarLinks: getCalendarLinks(state),
  downloadTickets,
  openTicketsDownloadModal,
  addToCalendarClicked,
})

export const SuccessActions = connect<SuccessActionsOwnProps, SuccessActionsRuntimeProps>(mapRuntime)(Presentation)
