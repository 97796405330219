import {isActive} from '@wix/wix-events-commons-statics'
import {State} from '../types'

export const isMoreDatesLabelVisible = (state: State) => !!state.upcomingOccurrencesCount

export const isLastAvailableOccurrence = (state: State) => isActive(state.event) && state.upcomingOccurrencesCount === 1

export const getMoreOccurrencesCount = (state: State) =>
  isActive(state.event) ? state.upcomingOccurrencesCount - 1 : state.upcomingOccurrencesCount

export const isMoreDatesButtonVisible = (state: State) => getMoreOccurrencesCount(state) > 0
