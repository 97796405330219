import React from 'react'
import {SelectBaseProps, SelectBaseState, SelectInputProps, SelectOptionsProps} from './interfaces'

export const SELECT_ELEMENTS = {
  SELECT: 'select-select',
  VALUE: 'select-value',
  OPTION: 'select-option',
  OPTIONS: 'select-options',
}

const SUPPORTED_KEYS = {
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
}

export class SelectBase extends React.Component<SelectBaseProps, SelectBaseState> {
  constructor(props: SelectBaseProps) {
    super(props)
    this.state = {
      expanded: false,
      selectedIndex: props.options.indexOf(props.selectedValue),
    }

    this.optionsRef = props.optionsContainerRef
  }

  optionsRef: React.RefObject<HTMLDivElement>
  selectRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    window.addEventListener('mousedown', this.hideOptionsIfClickedOutside)
  }

  componentDidUpdate(prevProps: SelectBaseProps) {
    const {options, selectedValue} = this.props

    if (prevProps.selectedValue !== this.props.selectedValue) {
      const {selectedIndex: stateSelectedIndex} = this.state
      const propsSelectedIndex = options.indexOf(selectedValue)

      if (stateSelectedIndex !== propsSelectedIndex) {
        this.setState({selectedIndex: propsSelectedIndex})
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.hideOptionsIfClickedOutside)
  }

  hideOptionsIfClickedOutside = (event: MouseEvent) => {
    const target = event.target as Element
    const targetDataHook = target.getAttribute('data-hook')
    const targetIsChild = this.selectRef.current?.contains?.(target)
    const elements = Object.values(SELECT_ELEMENTS)

    if (this.state.expanded && !(elements.includes(targetDataHook) && targetIsChild)) {
      this.hideOptions()
    }
  }

  handleKeyboard = (event: React.KeyboardEvent) => {
    const targetDataHook = (event.target as HTMLDivElement).getAttribute('data-hook')
    const isSelect = targetDataHook === SELECT_ELEMENTS.SELECT
    const isOptions = targetDataHook === SELECT_ELEMENTS.OPTIONS
    const keySupported = Object.values(SUPPORTED_KEYS).includes(event.keyCode)

    if ((isSelect || isOptions) && keySupported) {
      event.preventDefault()

      if (isSelect) {
        switch (event.keyCode) {
          case SUPPORTED_KEYS.ENTER:
          case SUPPORTED_KEYS.SPACE:
          case SUPPORTED_KEYS.ARROW_LEFT:
          case SUPPORTED_KEYS.ARROW_UP:
          case SUPPORTED_KEYS.ARROW_RIGHT:
          case SUPPORTED_KEYS.ARROW_DOWN:
            return this.showOptions()
          case SUPPORTED_KEYS.ESCAPE:
            return this.hideOptions()
          default:
            break
        }
      } else {
        switch (event.keyCode) {
          case SUPPORTED_KEYS.ENTER:
          case SUPPORTED_KEYS.SPACE:
            this.select(this.state.selectedIndex)
            return this.hideOptions()
          case SUPPORTED_KEYS.ARROW_LEFT:
          case SUPPORTED_KEYS.ARROW_UP:
            return this.markPreviousOption()
          case SUPPORTED_KEYS.ARROW_RIGHT:
          case SUPPORTED_KEYS.ARROW_DOWN:
            return this.markNextOption()
          case SUPPORTED_KEYS.ESCAPE:
            return this.hideOptions()
          default:
            break
        }
      }
    }
  }

  markNextOption = () =>
    this.setState(({selectedIndex}, {options}) => ({
      selectedIndex: selectedIndex < options.length - 1 ? selectedIndex + 1 : 0,
    }))

  markPreviousOption = () =>
    this.setState(({selectedIndex}, {options}) => ({
      selectedIndex: selectedIndex > 0 ? selectedIndex - 1 : options.length - 1,
    }))

  isSelected = (index: number) => index === this.state.selectedIndex

  select = (index: number) => {
    this.setState({selectedIndex: index})
    this.props.onChange(this.props.options[index])
    this.hideOptions()
  }

  toggleOptions = () => this.setState({expanded: !this.state.expanded})

  showOptions = () =>
    this.setState({expanded: true}, () => {
      this.optionsRef.current.focus()
      this.optionsRef.current.scrollIntoView(false)
    })

  hideOptions = () => this.setState({expanded: false})

  getInputProps = (): SelectInputProps => ({
    containerProps: {
      'data-hook': SELECT_ELEMENTS.SELECT,
      'aria-haspopup': 'listbox',
      expanded: this.state.expanded,
      tabIndex: 0,
      onClick: this.toggleOptions,
      onKeyDown: this.handleKeyboard,
    },
    valueProps: {
      'data-hook': SELECT_ELEMENTS.VALUE,
    },
  })

  getOptionsProps = (): SelectOptionsProps => ({
    containerProps: {
      'data-hook': SELECT_ELEMENTS.OPTIONS,
      'aria-activedescendant': `${this.state.selectedIndex}`,
      role: 'listbox',
      tabIndex: 0,
      onKeyDown: this.handleKeyboard,
      onBlur: this.hideOptions,
    },
    getOptionProps: (index: number) => ({
      'data-hook': SELECT_ELEMENTS.OPTION,
      'aria-selected': this.isSelected(index),
      role: 'option',
      id: `option-${index}`,
      key: `option-${index}`,
      selected: this.isSelected(index),
      onClick: () => this.select(index),
    }),
  })

  render() {
    return (
      <div ref={this.selectRef}>
        {this.props.renderInput(this.getInputProps())}
        {this.state.expanded && this.props.renderOptions(this.getOptionsProps())}
      </div>
    )
  }
}
